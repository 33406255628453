import React from "react";

function article_2() {
	return {
		date: "28 May 2023",
		title: "Challenging the Notion of \"If It Isn't Broke, Don't Fix It\"",
		description:
			"After two years of experience in contruction I outline some thoughts I have on the mindset of the industry.",
		keywords: [
			"The Benefits of Cloud Computing",
			"Ben",
			"Ben B",
			"Ben Baize",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<head>
						<title>
							Embracing Progress: Revolutionizing the Construction
							Industry for a Brighter Future
						</title>
					</head>
					<body>
						<h1>
							Embracing Progress: Revolutionizing the Construction
							Industry for a Brighter Future
						</h1>
						<img
							src="/ConstructionGang.png"
							alt="random"
							className="iphoneImage"
						/>
						<h3>Introduction:</h3>
						<p>
							The construction industry, a pillar of human
							civilization, has been built on a foundation of
							tradition and proven practices. Over the years, it
							has weathered countless challenges, erecting
							towering structures that shape our cities. However,
							as an electrical engineering student who has spent
							the past two summers interning within the
							construction industry, I can't help but notice that
							the old adage "If It Isn't Broke, Don't Fix It" is
							stifling the industry's potential for growth and improvement.
							During my time as a field tech and systems engineer,
							I've witnessed both the unoptimized processes and
							the glimmer of hope for change, particularly in my
							internship with Kiewit, a company that embraces
							progress and innovation.
						</p>

						<h3>
							Unoptimized Construction
							Industry:
						</h3>
						<p>
							In the past two years, I've had the privilege of
							observing the inner workings of the construction
							industry up close. While it is undeniably a realm of
							incredible feats, I've also encountered aspects that
							are undeniably stuck in their ways. The persistent adherence to
							outdated processes and a resistance to change,
							simply because "it works," has hindered the
							industry's potential for growth and development.
						</p>

						<img
							src="/PouringConcrete.png"
							alt="random"
							className="iphoneImage"
						/>
						<h3>
							The Fallacy of "If It Isn't Broke, Don't Fix It":
						</h3>
						<p>
							As an aspiring electrical engineer with a penchant
							for optimization, the phrase "If It Isn't Broke,
							Don't Fix It" has always grated on me. Just because
							something works doesn't mean it can't be improved.
							The construction industry's reliance on this mantra
							has perpetuated inefficiencies that have,
							unfortunately, become deeply ingrained over time.
							Innovation and progress should be celebrated, not
							feared, especially in an era that demands
							sustainable practices and heightened efficiency.
						</p>

						<h3>My Summer at Kiewit: A Beacon of Progress:</h3>
						<p>
							The summer of 2023 was a transformative period for
							me. Working with Kiewit, I was delighted to find a
							company that fostered a culture of openness to
							experimentation and optimization. Embracing my role
							as a systems engineer, I was encouraged to propose
							and implement new approaches to streamline processes
							and boost efficiencies.
						</p>
						<img
							src="/FedWay.png"
							alt="random"
							className="iphoneImage"
						/>
						<h3>
							Challenges Faced: Breaking Through the Resistance:
						</h3>
						<p>
							While Kiewit was more progressive compared to other
							companies in the industry, it was not without its
							challenges. Convincing some veteran workers to
							embrace change and abandon familiar methods was not
							always easy. The deeply-rooted tradition of the
							industry made some hesitant to take the risk,
							fearing potential disruptions. However, persistence
							and collaboration allowed us to make substantial
							progress.
						</p>

						<h3>The Power of Optimized Efficiency:</h3>
						<p>
							As an electrical engineering student, I couldn't
							ignore the vast potential for optimized efficiency
							in the construction industry. From implementing
							modern construction technologies to adopting
							data-driven decision-making, every small improvement
							we introduced had a cascading effect on overall
							productivity. The mantra of "If It Isn't Broke,
							Optimize It" became our guiding light.
						</p>

						<h3>Conclusion:</h3>
						<p>
							My journey within the construction industry has
							given me a unique perspective on its untapped
							potential. The phrase "If It Isn't Broke, Don't Fix
							It" should no longer hold us back from progress and
							innovation. By embracing change and adopting
							optimized efficiency, we can revolutionize the
							construction industry, building a future that is not
							only inspiring but sustainable. As an electrical
							engineering student, I am filled with hope and
							excitement for the transformative possibilities that
							lie ahead. Together, we can construct a brighter,
							better world.
						</p>
						<img
							src="\futureconstruction.jpg"
							alt="random"
							className="randImg"
						/>
					</body>
				</div>
			</React.Fragment>
		),
	};
}

function article_1() {
	return {
		date: "10 May 2023",
		title: "Innov8x",
		description:
			"My experiences with the monthly innovation challenges at Colorado School of Mines.",
		style: ``,
		keywords: [
			"Innov8x",
			"Ben Baize",
			"Ben B",
			"Ben Baize",
		],
		body: (
			<React.Fragment>
				<head>
					<title>
						Innov8x: Unleashing the Power of Innovation at Colorado
						School of Mines
					</title>
				</head>
				<body>
				<img
							src="\Innov8x.png"
							alt="random"
							className="randImg"
						/>
					<h1>
						Innov8x: Unleashing the Power of Innovation at Colorado
						School of Mines
					</h1>

					<p>
						At the Colorado School of Mines, I had the incredible
						opportunity to participate in a series of innovation
						challenges called Innov8x. These intense 3-hour events
						brought together teams of 2-3 people to tackle
						real-world problems, developing innovative solutions and
						presenting them to judges and stakeholders. The
						challenges not only tested our technical skills but also
						our ability to think on our feet and pitch our ideas
						effectively.
					</p>
					<img
							src="\innov8x2.jpeg"
							alt="random"
							className="iphoneImage"
						/>
					<h2>Embracing the Thrill of Fast Innovation</h2>

					<p>
						Innov8x challenged us to think outside the box, pushing
						the boundaries of what we thought was possible. With
						just three hours on the clock, we had to rapidly ideate,
						design, and prototype our solutions. The pressure was
						intense, but it was during these time-constrained events
						that we truly unleashed the power of innovation. The
						adrenaline-fueled environment ignited our creativity,
						leading us to develop unique and groundbreaking
						solutions.
					</p>

					<p>
						One of the challenges my team emerged victorious in was
						the modular housing framework construction. We devised
						an innovative approach that significantly reduced
						construction time and costs, while still ensuring the
						structures were robust and sustainable. This victory was
						a testament to our collaborative spirit and
						problem-solving abilities.
					</p>
					<img
							src="\innov8x1.jpeg"
							alt="random"
							className="iphoneImage"
						/>
					<h2>Innovation Takes the Center Stage</h2>

					<p>
						One of the most memorable Innov8x events was the
						Toyota-sponsored "Innovate the Future" challenge.
						Collaborating with my talented team, we tackled complex
						problems in the automotive industry. Together, we
						developed a forward-thinking solution that combined
						renewable energy sources with cutting-edge electric
						vehicle technology. This challenge was particularly
						thrilling, as it allowed us to contribute to shaping the
						future of transportation.
					</p>

					<p>
						While not every challenge resulted in a victory, it was
						the inaugural Innov8x event where my team secured the
						second-place spot that remains etched in my memory. We
						presented an innovative application of augmented reality
						(AR) technology to visualize and simulate surgeries and
						MRIs. This experience taught us valuable lessons about
						refining ideas and effectively communicating their
						potential to the judges and stakeholders.
					</p>

					<h2>
						Beyond Engineering: Nurturing Well-Rounded Innovators
					</h2>

					<p>
						Participating in Innov8x wasn't just about honing my
						engineering skills; it also exposed me to the world of
						business and entrepreneurship. Crafting compelling
						pitches that conveyed the value of our solutions
						challenged us to think like entrepreneurs. We had to
						consider not only the technical feasibility of our ideas
						but also their market viability and potential impact.
					</p>

					<p>
						The innovation challenges opened doors to personal
						growth and allowed me to become a more well-rounded
						individual. As we stood before the judges and
						stakeholders, articulating our ideas with passion and
						conviction, I discovered the importance of effective
						communication and storytelling in driving innovation
						forward.
					</p>

					<h2>Gratitude to the Visionary Behind Innov8x</h2>

					<p>
						None of these incredible experiences would have been
						possible without the visionary leadership of Sid Saleh,
						the Innovation Center director. Sid's dedication to
						fostering a culture of innovation and pushing the
						boundaries of what is possible has been truly inspiring.
						Through Innov8x, he has provided countless students,
						including myself, with a platform to transform ideas
						into reality.
					</p>

					<p>
						In conclusion, my journey with Innov8x at Colorado
						School of Mines has been nothing short of
						transformative. These innovation challenges have
						nurtured my passion for creative problem-solving and
						allowed me to embrace the exhilarating world of
						fast-paced innovation. I am deeply grateful for the
						opportunity to participate in these events and excited
						for what the future holds as I continue my journey as an
						innovator and engineer.
					</p>
				</body>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "",
		title: "Norwegian AI Summer",
		description:
			"AI in Norway coupled with my adventures living in Oslo",
		style: ``,
		keywords: [
			"Norway",
			"Ben Baize",
			"Ben B",
			"Ben Baize",
		],
		body: (
			<React.Fragment>
				<head>
					<title>
						Norwegian AI Summer: AI in Norway coupled with my adventures living in Oslo.
					</title>
				</head>
				<body>
				<img
							src="\priekestolen.png"
							alt="random"
							className="randImg"
						/>
					<h1>
					Norwegian AI Summer: AI in Norway coupled with my adventures living in Oslo.
					</h1>

					<p>
						...
					</p>
					<img
							src="\dyreparken.png"
							alt="random"
							className="randImg"
						/>
					<h2>..</h2>

					
				</body>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2, article_3];

export default myArticles;
