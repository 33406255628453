import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./kiewit.png"
								alt="kiewit"
								className="work-image"
							/>
							<div className="work-title">Kiewit</div>
							<div className="work-subtitle">
								Systems Engineering Intern
							</div>
							<div className="work-duration">2023 Summer</div>
						</div>

						<div className="work">
							<img
								src="./mineslogo.png"
								alt="mines"
								className="work-image"
							/>
							<div className="work-title">Colorado School of Mines</div>
							<div className="work-subtitle">
								Peer Mentor/Teacher
							</div>
							<div className="work-duration">Fall 2021 - Spring 2024</div>
						</div>

						<div className="work">
							<img
								src="./noraai.png"
								alt="nora"
								className="work-image"
							/>
							<div className="work-title">NORA.ai</div>
							<div className="work-subtitle">
								AI Intern
							</div>
							<div className="work-duration">2024 Summer</div>
						</div>

						<div className="work">
							<img
								src="./vine.png"
								alt="vine"
								className="work-image"
							/>
							<div className="work-title">Vine Laboratories</div>
							<div className="work-subtitle">
								Engineering Field Technician
							</div>
							<div className="work-duration">2022 Summer</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
