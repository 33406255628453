const INFO = {
	main: {
		title: "Ben Baize Portfolio",
		name: "Ben Baize",
		email: "ben.baize617@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/benjakson",
		linkedin: "https://www.linkedin.com/in/ben-baize/",
		instagram: "https://www.instagram.com/b_biaze/",
	},

	homepage: {
		title: "Swiss Army Knife of Engineering",
		description:
			"I am an electrical engineering student with a focus in integrated circuits and electronics. I have experience in designing PCBs, coding in 4+ languages, creating ML models, Electrical CAD, and multiple OS environments. I enjoy solving complex problems and learning new skills about anything. I am always looking for new challenges and opportunities to grow as a well-rounded Engineer.",
	},

	about: {
		title: "I’m Ben Baize. I live in Golden, Colorado, where I am studying Electrical Engineering.",
		description:
			"I am a driven Electrical Engineering student at Colorado School of Mines, with a B.S. in Electrical Engineering, followed by a M.S. in Electrical Engineering that I am expecting to get in May 2025. As a Grewcock Presidential Scholar, I've cultivated strong leadership skills through exclusive workshops with top industry leaders. With hands-on experience as an Engineering Field Technician, AI Intern, and a Systems Engineering Intern, coupled with proficiency in programming languages and technical tools, I am ready to bring innovation and success to the world.",
	},

	articles: {
		title: "I have a passion for growing and learning as much as possible, so these are some thoughts of my mine.",
		description:
			"Chronological storage for my current thoughts on anything related in someway to technology.",
	},

	projects: [
		{
			title: "Remote Control Lawnmower",
			description:
				"A fully remote controlled lawn mower built from an old push mower.",
			logo: "/mower.png",
			linkText: "View Project",
			link: "/RCmower",
		},

		{
			title: "Sports Betting AI",
			description:
				"Scraped nba box scores to create an Tensorflow model to predict nba games",
			logo: "/betting.png",
			// linkText: "View Project",
			// link: "https://github.com",
		},

		{
			title: "APOLLONIA",
			description:
				"Designed from ground up, a robot to fully navigate a randomized course autonomously using computer vision.",
			logo: "/arduinoandpython.png",
			linkText: "View Project",
			link: "/APOLLONIA",
		},

		{
			title: "ADDAZU",
			description:
				"Collaboratively helped design insulation for a modular housing solution.",
			logo: "/addazu.png",
			linkText: "View Addazu",
			link: "https://addazu.com/",
		},

		{
			title: "PDF Conversion Solution",
			description:
				"Made a custom application to transform PDFs into a specific Excel for requirements management using python.",
			logo: "/pdfconvert.png",
			linkText: "View Project",
			link: "https://github.com/benjakson/PDF_Excel_Converter",
		},
		{
			title: "AI Requirement Manger",
			description:
				"Made a custom application to perform requirement management tasks such as allocating disciplines, defining text as a requirement or information, and defining what type of requirement the text is.",
			logo: "/pdfconvert.png",
			linkText: "View Project",
			link: "https://github.com/benjakson/Requirements_Management_AI",
		},
	],
};

export default INFO;
