import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";
import "./styles/projects.css";

const APOLLONIAROBOT = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const currentSEO = SEO.find((item) => item.page === "APOLLONIA");
	return (
		<React.Fragment>
			<div>
				<NavBar active="projects" />
				<div className="video-container">
					<video controls width="100%" height="100%">
						<source src="/robot.mp4" type="video/mp4" />
						{/* Add other video format sources here if needed */}
						Your browser does not support the video tag.
					</video>
				</div>
				<div className="powerpoint-container">
					{/* Use an iframe to display the PDF version of the PowerPoint presentation */}
					<iframe
						title="Robot PowerPoint Presentation"
						src="/robot.pdf"
						width="100%"
						height="1100px"
						style={{ border: "none" }}
					/>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default APOLLONIAROBOT;
