import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";
const Mower = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const currentSEO = SEO.find((item) => item.page === "RCmower");
	return (
		<React.Fragment>
			<div>
				<NavBar active="projects" />
				<div className="container">
					<h1>Remote Control Lawnmower Project</h1>

					<p>
						During my journey as an electrical engineering
						enthusiast, I encountered a significant obstacle in my
						life when I fractured my back while playing soccer. This
						unfortunate event left me unable to perform daily tasks,
						including mowing the lawn. However, rather than being
						discouraged, I saw this as an opportunity to combine my
						passion for engineering with my determination to
						overcome challenges.
					</p>

					<p>
						Thus, I embarked on an ambitious project to design and
						build a remote control lawnmower from scratch. I was
						determined to regain control of this routine task by
						leveraging my creativity, engineering knowledge, and
						problem-solving skills.
					</p>

					<h2>Designing and Building the Remote Control Lawnmower</h2>

					<p>
						The project involved multiple facets of electrical
						engineering, from designing the control system to
						constructing the physical framework. I started by
						researching and selecting the appropriate components for
						the remote control system, including motor controllers,
						an RC receiver, and transmitter.
					</p>

					<p>
						After thorough planning, I moved on to fabricating the
						frame for the lawnmower. I carefully considered the
						materials and their durability to ensure the finished
						product would be robust and efficient in operation. I
						even repurposed an old push mower as the foundation for
						my remote control lawnmower, demonstrating my
						resourcefulness and commitment to sustainability. I used
						aluminum and construction grade glue to put the
						framework together. I included threaded rods to allow an
						adjustable cutting height. Finally, I used Electric
						wheel chair motors and a car battery to power and move
						the whole mower.
					</p>

					<h2>
						Achieving Success through Creativity and Engineering
						Knowledge
					</h2>

					<p>
						As I navigated through the complexities of the project,
						my engineering knowledge and problem-solving abilities
						were put to the test. I encountered various challenges,
						from integrating the control system seamlessly to
						ensuring the safety of the mower while in operation.
					</p>

					<p>
						Through relentless determination and a touch of
						creativity, I was able to overcome each hurdle. My
						expertise in electrical engineering enabled me to design
						and implement an efficient and responsive control
						system. I fine-tuned the motor controllers and
						transmitter to provide precise maneuverability, ensuring
						the lawnmower could navigate the lawn effectively.
					</p>

					<h2>Conclusion</h2>

					<p>
						My remote control lawnmower project is not just a
						testament to my technical skills, but also to my
						resilience and determination in the face of adversity.
						It is a project that has shaped my journey as an
						electrical engineering enthusiast, igniting my drive to
						make a positive impact through innovation and
						creativity.
					</p>

					<p>
						As I continue to pursue opportunities in electrical
						engineering, this project will always hold a special
						place in my heart, reminding me of the power of
						engineering to transform lives and empower independence.
					</p>
				</div>
				<div className="video-container">
					<video controls width="100%" height="100%">
						<source src="/mower-video.mp4" type="video/mp4" />
						{/* Add other video format sources here if needed */}
						Your browser does not support the video tag.
					</video>
				</div>
				<div className="page-footer">
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Mower;
